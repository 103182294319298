.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

#bowie-maze {
  opacity: 0 ;
}
#left-bubble {
  opacity: 0;
}
#right-bubble {
  opacity: 0;
}
#location {
  opacity: 0;
}
#venue {
  opacity: 0;
}
#date {
  opacity: 0;
}
#bowie-color {
  opacity: 0 ;
}
#question-mark {
  opacity: 0 ;
}
#play {
   opacity: 0 ;
 }
#next {
    opacity: 0 ;
  }
#or {
  opacity: 0 ;
}


.play-game {
  #question-mark {
    animation: yo-yo 3s;
    animation-iteration-count: infinite;
    font-family: 'Open Sans Condensed', sans-serif;

  }
  #or {
    opacity: 1;
    font-family: 'Open Sans Condensed', sans-serif;

  }
  #play {
    animation: fade-in 1s;
    animation-delay: 3s;
    animation-fill-mode: forwards;
    font-family: 'Open Sans Condensed', sans-serif;
  }
  #left-bubble {
    animation: fade-in 1s;
    animation-delay: 3s;
    animation-fill-mode: forwards;
  }
}

.end-game {
  #or {
    opacity: 1;
  }
  #date {
    animation: fade-in 0.5s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
  }
  #location {
    animation: fade-in 0.5s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
  }
  #venue {
    animation: fade-in 0.5s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
    font-family: 'Open Sans Condensed', sans-serif;
  }
  #right-bubble {
    animation: fade-in 0.5s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
  }
  #play {
    animation: fade-in 1s;
    animation-delay: 10s;
    animation-fill-mode: forwards;
    font-family: 'Open Sans Condensed', sans-serif;
  }
  #left-bubble {
    animation: fade-in 1s;
    animation-delay: 10s;
    animation-fill-mode: forwards;
  }
}

.bowie-animation {
  #bowie-color {
    animation: fade-in 0.5s;
    animation-fill-mode: forwards;
  }
  #next {
    animation: fade-in 0.5s;
    animation-delay: 1.5s;
    animation-fill-mode: forwards;
    font-family: 'Open Sans Condensed', sans-serif;
  }
  #left-bubble {
    animation: fade-in 0.5s;
    animation-delay: 1.5s;
    animation-fill-mode: forwards;
  }
  #date {
    animation: fade-in 0.5s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
    font-family: 'Open Sans Condensed', sans-serif;
  }
  #location {
    animation: fade-in 0.5s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
  }
  #venue {
    animation: fade-in 0.5s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
  }
  #right-bubble {
    animation: fade-in 0.5s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
  }
}

.maze-animation {
  #bowie-maze {
    animation: fade-in 0.5s;
    animation-fill-mode: forwards;
  }
  #next {
    animation: fade-in 0.5s;
    animation-delay: 1.5s;
    animation-fill-mode: forwards;
    font-family: 'Open Sans Condensed', sans-serif;

  }
  #left-bubble {
    animation: fade-in 0.5s;
    animation-delay: 1.5s;
    animation-fill-mode: forwards;
  }
  #date {
    animation: fade-in 0.5s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
    font-family: 'Open Sans Condensed', sans-serif;

  }
  #location {
    animation: fade-in 0.5s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
    font-family: 'Open Sans Condensed', sans-serif;

  }
  #venue {
    animation: fade-in 0.5s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
    font-family: 'Open Sans Condensed', sans-serif;

  }
  #right-bubble {
    animation: fade-in 0.5s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
    font-family: 'Open Sans Condensed', sans-serif;

  }
}

.bowie-end-animation {
  #bowie-color {
    animation: fade-in 0.5s;
    animation-fill-mode: forwards;
  }
}

.maze-end-animation {
  #bowie-maze {
    animation: fade-in 0.5s;
    animation-fill-mode: forwards;
  }
}

.bounce-between {
  #question-mark {
    opacity: 0.1 ;
    font-family: 'Open Sans Condensed', sans-serif;

  }
  #bowie-maze {
    animation: yo-yo 1s;
    animation-iteration-count: infinite;
  }
  #bowie-color {
    animation: yo-yo 1s;
    animation-delay: 500ms;
    animation-iteration-count: infinite;
  }
}

.LinearProgress {

}

@keyframes yo-yo {
  0% {opacity: 0;}
  50% {opacity: 0.1;}
  100% {opacity: 0;}
}

@keyframes disappear {
  0% {opacity: 1;}
  95% {opacity: 1;}
  100% {opacity: 0;}
}
@keyframes fade-in {
  0% {opacity: 0;}
  100% {opacity: 1;}
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
